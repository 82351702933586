@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@layer base {
    html {
        font-family: 'Inter', sans-serif;
    }

    ul {
        @apply list-disc text-white text-[33px] leading-[115%]
    }

    ul li:not(:last-child) {
        @apply mb-[12px]
    }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    @apply !hidden
}
